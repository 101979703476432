window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;

import $ from 'jquery'
import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import { vMaska } from 'maska';
import Dropdown from 'primevue/dropdown';
import Select from 'primevue/select';
import PrimeVue from 'primevue/config';
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIISoul from "./soft-ui-iisoul";
import VueSweetalert2 from "vue-sweetalert2";
import Axios from './services/axios.service';
import Aura from '@primevue/themes/aura';
import { definePreset } from '@primevue/themes';

window.$ = $;
window.jQuery = $;
window.Axios = new Axios();

const appInstance = createApp(App);


const MyPreset = definePreset(Aura, {
  semantic: {
      primary: {
          50: '{orange.50}',
          100: '{orange.100}',
          200: '{orange.200}',
          300: '{orange.300}',
          400: '{orange.400}',
          500: '{orange.500}',
          600: '{orange.600}',
          700: '{orange.700}',
          800: '{orange.800}',
          900: '{orange.900}',
          950: '{orange.950}'
      }
  }
});




appInstance.use(VueSweetalert2);
appInstance.use(store);
appInstance.use(PrimeVue, { theme: {preset: MyPreset} });
appInstance.component('DropdownSoft', Dropdown);
appInstance.component('BaseSelect2', Select);
appInstance.use(router);
appInstance.directive('maska', vMaska);
appInstance.use(SoftUIISoul);
appInstance.use(VueSweetalert2);
appInstance.mount("#app");